import React from "react";
import "./Layout.scss";
import "../../styles/globals.scss";
// import Navbar from "../../components/Navbar/Navbar";
// import Footer from "../../components/Footer/Footer";
import Wrapper from "../Wrapper/Wrapper";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

const Layout = (props) => {
  return (
    <Wrapper>
      <div className={"Layout" + (props.bookingActive ? " book-active" : "")}>
        <Header
          ableToGoBack={props.ableToGoBack}
          setFinishBookingStep={props.setFinishBookingStep}
          finishBookingStep={props.finishBookingStep}
          isHome={props.isHome}
        />
        {/* <Navbar
          bookingActive={props.bookingActive}
          serviceChosen={props.serviceChosen}
          ableToGoBack={props.ableToGoBack}
          setFinishBookingStep={props.setFinishBookingStep}
          finishBookingStep={props.finishBookingStep}
          chosenCity={chosenCity}
          hiddenNav={props.hiddenNav}
        /> */}
        {props.children}
        {!props.bookingActive && <Footer />}
      </div>
    </Wrapper>
  );
};

export default Layout;
