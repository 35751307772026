import "./Header.scss";
import { Link, navigate } from "gatsby";
import React, { useCallback, useEffect, useState } from "react";
import LogoRegular from "../../assets/icons/Logo-1.svg";
import LogoBlack from "../../assets/icons/Logo-2.svg";
import { BsChevronLeft } from "react-icons/bs";

export default function Header({
  ableToGoBack,
  setFinishBookingStep,
  finishBookingStep,
  isHome,
}) {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [scrolledTop, setScrolledTop] = useState(false);

  const handleScroll = useCallback(() => {
    if (document.documentElement.scrollTop > 50) {
      setScrolledTop(true);
    } else setScrolledTop(false);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    //eslint-disable-next-line
  }, []);

  return (
    <header
      className={"header" + (scrolledTop || ableToGoBack ? " scrolled" : "")}
    >
      <nav className="header__nav">
        {!isHome && (
          <button
            className="back"
            onClick={() => {
              if (finishBookingStep && finishBookingStep > 2) {
                setFinishBookingStep((p) => p - 1);
                window.scrollTo(0, 0);
              } else {
                finishBookingStep ? setFinishBookingStep(null) : navigate("/");
              }
            }}
          >
            <BsChevronLeft />
          </button>
        )}
        <a href="https://www.bildagroup.se/" className="header__logo">
          <LogoRegular />
        </a>
        <a href="https://www.bildagroup.se/" className="header__logo-black">
          <LogoBlack />
        </a>

        {isHome ? (
          <div className="header__hamburger-container">
            <div
              className={"hamburger" + (hamburgerOpen ? " open" : "")}
              onClick={() => setHamburgerOpen((p) => !p)}
              aria-hidden="true"
            >
              <span className="line first"></span>
              <span className="line second"></span>
              <span className="line third"></span>
            </div>
          </div>
        ) : (
          <div style={{ width: 40, height: 21 }}></div>
        )}

        <div className={"navigation" + (hamburgerOpen ? " open" : "")}>
          <ul>
            <li>
              <a href="https://www.bildagroup.se/">Hem</a>
            </li>
            <li>
              <a href="https://www.bildagroup.se/about/">Om oss</a>
            </li>
            <li>
              <a href="https://www.bildagroup.se/entreprenand/">För Företag</a>
            </li>
            <li>
              <a href="https://www.bildagroup.se/bilda-fastighetsservice/">
                För Privatpersoner
              </a>
            </li>
            <li>
              <Link to="/">Våra priser</Link>
            </li>
            <li>
              <a href="https://www.bildagroup.se/contact/">Kontakt</a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}
