import React from "react";
import "./Footer.scss";
import LinkedinIcon from "../../assets/icons/linkedin.svg";
import FacebookIcon from "../../assets/icons/facebook.svg";
import InstagramIcon from "../../assets/icons/instagram.svg";
import PhoneIcon from "../../assets/icons/phone.svg";
import EmailIcon from "../../assets/icons/email.svg";
import dayjs from "dayjs";

const Footer = () => {
  return (
    <footer className="footer" role="contentinfo">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-sm-12">
            <div className="footer__left-container">
              <div className="footer__left-label">BILDA GROUP</div>
              <div className="footer__left-title">
                <p>
                  Din samarbetes partner som hjälper till att förverkliga
                  <strong>dina ideér</strong>
                </p>
              </div>
              <div className="footer__social-icons-wrapper">
                <a className="footer__social-icons-wrapper__icons" href="#!">
                  <LinkedinIcon />
                </a>
                <a className="footer__social-icons-wrapper__icons" href="#!">
                  <FacebookIcon />
                </a>
                <a className="footer__social-icons-wrapper__icons" href="#!">
                  <InstagramIcon />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-12">
            <div className="footer__right-continer">
              <div className="footer__right-title">Vision</div>
              <div className="footer__right-description">
                Flyttstäd, Renovering, <br />
                Personaluthyrning
              </div>
              <div className="footer__right-title">Kontakt</div>
              <div className="footer__right-description">Måndag - Fredag</div>
              <div className="footer__info">
                <a className="footer__info__link" href="tel:031-148899">
                  <PhoneIcon />
                  031-148899
                </a>
                <a className="footer__info__link" href="malto:info@bilda.se">
                  <EmailIcon />
                  info@bilda.se
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer__copyright">
          @ {dayjs(new Date()).format("YYYY")} Bern Group, All Rights Reserved
        </div>
      </div>
    </footer>
  );
};

export default Footer;
